import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  DateInput,
  Checkbox,
  RadioButton,
  SelectInput,
  SelectInputDirect,
  MultiSelectInput,
  TextArea,
  WYSIWIGInput,
  FileInput,
  CurrencyInput,
  PlacesInput,
  SuggestionInput
} from '..';

const Input = props => {
  let element = null;
  const { type, options, className, error } = props;

  if (type === 'dateTime') {
    element = <DateInput {...props} />;
  } else if (type === 'checkbox') {
    element = <Checkbox {...props} />;
  } else if (type === 'radioButton') {
    element = options.map(e => <RadioButton key={e.id} {...props} {...e} />);
  } else if (type === 'select') {
    element = <SelectInput {...props} />;
  } else if (type === 'selectdirect') {
    element = <SelectInputDirect {...props} />;
  } else if (type === 'multiSelect') {
    element = <MultiSelectInput {...props} />;
  } else if (type === 'textArea') {
    element = <TextArea {...props} />;
  } else if (type === 'wysiwig') {
    element = <WYSIWIGInput {...props} />;
  } else if (type === 'fileInput') {
    element = <FileInput {...props} />;
  } else if (type === 'currencyInput') {
    element = <CurrencyInput {...props} />;
  } else if (type === 'placesInput') {
    element = <PlacesInput {...props} />;
  } else if (type === 'suggestionInput') {
    element = <SuggestionInput {...props} />;
  } else {
    element = <TextInput {...props} />;
  }

  const classes = className + (error ? ' validation-fail' : '');

  return (
    <div className={classes}>
      {element}
      {error && (
        <span className={`message${type === 'radioButton' ? ' d-block' : ''}`}>
          {error}
        </span>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  className: PropTypes.string,
  error: PropTypes.string
};

Input.defaultProps = {
  options: null,
  className: 'col-10',
  error: null
};

export default Input;
