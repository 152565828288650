import React from 'react';
import { Create, Pdf } from '../components/Offer';
import { PublicRoute, PrivateRoute } from '../components/Authentication';

export default [
  <PrivateRoute path="/offers/create" component={Create} exact key="create" />,
  <PrivateRoute
    path="/offers/:id/pdf"
    component={Pdf}
    key="offer_pdf"
    title="Offer"
  />,
  <PublicRoute
    path="/offers/:id/view"
    component={Pdf}
    key="offer_view"
    title="Offer"
  />
];
