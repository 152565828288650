/* eslint-disable react/no-danger */
/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { iconClose, Loader } from '../Util';
import {
  formatDateSimple,
  hasRole,
  numberToCHF,
  pendingStatusTypes,
  formatFeedback,
  ucwords
} from '../../utils/helpers';
import {
  updateStatus as updateOfferStatusAction,
  reset as resetOfferStatusAction
} from '../../actions/Offer/update';
import {
  CLIENT,
  SUB_CLIENT,
  VENDOR,
  HOST_ADMIN,
  HOST
} from '../../constants/roles';
import { list as listDashboardAction } from '../../actions/Dashboard/list';
import { Map, EmailForm, Feedback } from '.';

const sliderSettings = {
  className: 'modal-slider',
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '100px',
  dots: true,
  arrows: false,
  autoplay: false,
  variableWidth: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: true,
        centerMode: true,
        centerPadding: '25px'
      }
    }
  ],
  accessibility: false
};

const Detail = ({
  offer,
  offers,
  offerUpdateLoading,
  offerUpdateError,
  updatedOffer,
  request,
  setOffer,
  roles,
  updateOfferStatusAction,
  resetOfferStatusAction
}) => {
  useEffect(() => {
    if (updatedOffer) {
      listDashboardAction();
      setOffer(updatedOffer);

      toast.success('Request updated successfully.');
    }

    if (offerUpdateError) {
      toast.error('Could not update request.');
    }

    resetOfferStatusAction();
  }, [updatedOffer, offerUpdateError]);

  return !offer ? (
    <div />
  ) : (
    <Row className="mt-4">
      {offerUpdateLoading && <Loader />}
      <Col md={12}>
        <h2>Offer #{offer.id}</h2>
      </Col>

      <Col md={12}>
        <div className="modal__content__infos mb-3">
          {!hasRole(roles, VENDOR) && (
            <EmailForm
              id={offer.id}
              initialValues={{
                recipient: request.guestEmail,
                cc: request.user.email
              }}
            />
          )}
          <span className="d-flex">
            <strong className="w-25 d-inline-block">Description</strong>{' '}
            <div
              className="w-70 d-inline-block ml-1"
              dangerouslySetInnerHTML={{
                __html: sanitize(offer.description)
              }}
            />
          </span>
          <strong className="w-25 d-inline-block">Exact Date Match</strong>{' '}
          {!offer.availabilityDiffers ? 'Yes' : 'No'}
          <br />
          {offer.availabilityDiffers && (
            <>
              <strong className="w-25 d-inline-block">Available Dates</strong>{' '}
              {formatDateSimple(offer.availableFrom)}
              {offer.availableTo
                ? ` - ${formatDateSimple(offer.availableTo)}`
                : ' - Open end'}
              <br />
            </>
          )}
          <strong className="w-25 d-inline-block">Apartment Type</strong>{' '}
          {offer.apartmentType ? offer.apartmentType.name : ''}
          <br />
          <strong className="w-25 d-inline-block">Address</strong>
          {` ${offer.address}`}
          <br />
          <strong className="w-25 d-inline-block">&nbsp;</strong>
          {` ${offer.zip} ${offer.city}`}
          <br />
          <strong className="w-25 d-inline-block">Apartment ID</strong>
          {offer.apartmentIdentifier ? ` ${offer.apartmentIdentifier}` : ''}
          <br />
          <strong className="w-25 d-inline-block">Cancellation Terms</strong>
          {` ${offer.cancellationTerms}`}
          <br />
          <span className="d-flex">
            <strong className="w-25 d-inline-block">Remarks</strong>
            <div
              className="w-70 d-inline-block ml-1"
              dangerouslySetInnerHTML={{
                __html: sanitize(offer.comment)
              }}
            />
          </span>
          <span className="d-flex">
            <strong className="w-25 d-inline-block">Apartment Link</strong>{' '}
            <a
              href={offer.link}
              className="w-75 d-inline-block ml-1 text--link-pink text-break"
            >
              {offer.link}
            </a>
          </span>
          <strong className="w-25 d-inline-block">
            {`${ucwords(request.requestedRateType)} Price (${offer.vatCategory === "inclusive" ? "incl. " : "excl. "} ${
              offer.vatPrice === "" ? process.env.REACT_APP_VAT_HOSPITALITY_FEE : offer.vatPrice
            }% VAT)`}
          </strong>{' '}
          {numberToCHF(offer.price)}
          <br />
          <strong className="w-25 d-inline-block">
            Total End Cleaning Fee ({offer.vatCategory === "inclusive" ? "incl. " : "excl. "}
            {offer.vatCleaning === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatCleaning}% VAT)
          </strong>{' '}
          {numberToCHF(offer.endCleaningFee || 0)}
          {!!request.pets && (
            <>
              <br />
              <strong className="w-25 d-inline-block">
              {ucwords(request.requestedRateType)} Pet Fee ({offer.vatCategory === "inclusive" ? "incl. " : "excl. "}
                      {offer.vatPet === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatPet}% VAT)
              </strong>{' '}
              {numberToCHF(offer.petFee)}
            </>
          )}
          <br />
          {!!request.babyPackage && (
            <>
              <strong className="w-25 d-inline-block">
                {ucwords(request.requestedRateType)} Baby Package Fee ({offer.vatCategory === "inclusive" ? "incl. " : "excl. "}
                  {offer.vatBaby === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatBaby}% VAT)
              </strong>{' '}
              {numberToCHF(offer.babyPackageFee)}
              <br />
            </>
          )}
          {!!request.parking && (
            <>
              <strong className="w-25 d-inline-block">
              {ucwords(request.requestedRateType)} Parking Fee ({offer.vatCategory === "inclusive" ? "incl. " : "excl. "}
                            {offer.vatParking === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatParking}% VAT)
              </strong>{' '}
              {numberToCHF(offer.parkingFee)}
              <br />
            </>
          )}
        </div>
        {!!(offer.charges && offer.charges.length !== 0) && (
          <div className="modal__content__infos mb-3">
            <strong className="d-inline-block">Other Charges</strong>{' '}
            <table style={{ width: '50%' }}>
              <thead>
                <tr>
                  <td>
                    <strong className="w-30">Type</strong>
                  </td>
                  <td>
                    <strong className="w-30">Frequency</strong>
                  </td>
                  <td>
                    <strong className="w-30">Vat</strong>
                  </td>
                  <td>
                    <strong className="w-30">Price</strong>
                  </td>    
                  <td>
                    <strong className="w-30">Remark</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {offer.charges.map(e => {
                  return (
                    <tr key={e.id}>
                      <td>{e.offerChargeType.name}</td>
                      <td>{e.offerChargeFrequency.name}</td>
                      <td>
                        {e.offerChargeVat ? e.offerChargeVat.vat ? `${offer.vatCategory === "inclusive" ? "incl. " : "excl. "}${e.vatPercent || ((e.offerChargeVat.vat * 10) * (100 * 10) / (10 * 10))}% VAT` : e.offerChargeVat && e.offerChargeVat.vat ? e.offerChargeVat.vat : '' : ''}
                      </td>
                      <td nowrap="nowrap">{numberToCHF(e.price)}</td>
                      <td nowrap="nowrap">{e.remark || 'None'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {!hasRole(roles, [CLIENT, SUB_CLIENT]) && (
          <>
            <span id="feedback" className="d-flex">
              <strong className="w-25 d-inline-block">
                Feedback from client
              </strong>
              &nbsp;
              <div
                className="w-70 d-inline-block"
                dangerouslySetInnerHTML={{
                  __html: sanitize(formatFeedback(offer))
                }}
              />
            </span>
            <br />
          </>
        )}
      </Col>
      <Col md={12}>
        {request && offers && offers['hydra:member'].length && (
          <Map
            request={request}
            offers={offers}
            selectedOffer={offer}
            setSelectedOffer={setOffer}
          />
        )}
      </Col>
      {!!offer.images.length && (
        <Col md={12} className="mt-4">
          <Slider {...sliderSettings}>
            {offer.images.map(e => {
              return (
                <div key={e['@id']}>
                  <img
                    style={{ height: '600px' }}
                    src={`${process.env.REACT_APP_API_ENTRYPOINT}/${
                      process.env.REACT_APP_IMAGES_UPLOAD_DIR_USER
                    }/${e.path}`}
                    alt="Apartment"
                  />
                </div>
              );
            })}
          </Slider>
        </Col>
      )}
      {hasRole(roles, [CLIENT, SUB_CLIENT, HOST_ADMIN]) &&
        offer.status.name === 'pending' &&
        pendingStatusTypes.indexOf(request.status.name) !== -1 && (
          <Feedback request={request} offer={offer} />
        )}
      {offer &&
        hasRole(roles, [VENDOR, HOST, HOST_ADMIN]) &&
        offer.status.name === 'accepted' &&
        pendingStatusTypes.indexOf(request.status.name) !== -1 && (
          <Col md={12} className="text-center text-md-right mt-4">
            <button
              type="button"
              className="btn btn--decline-offer"
              onClick={() => updateOfferStatusAction(offer.id, 'close')}
            >
              Withdraw offer{' '}
              <img
                src={iconClose}
                alt="Decline offer"
                className="btn__close-icon"
              />
            </button>
            <button
              type="button"
              className="btn btn--primary"
              onClick={() => updateOfferStatusAction(offer.id, 'confirm')}
            >
              Confirm Offer
            </button>
          </Col>
        )}
    </Row>
  );
};

const mapStateToProps = state => {
  const { retrieved: offers } = state.offer.list;

  const {
    updateLoading: offerUpdateLoading,
    updateError: offerUpdateError,
    updated: updatedOffer
  } = state.offer.update;

  return {
    offers,
    offerUpdateLoading,
    offerUpdateError,
    updatedOffer
  };
};

const mapDispatchToProps = {
  updateOfferStatusAction,
  resetOfferStatusAction,
  listDashboardAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Detail);
