import React from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

export const formatDate = (
  dateString,
  locale = 'de-CH',
  options = {
    year: 'numeric', // 'numeric' | '2-digit',
    month: '2-digit', // 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
    day: '2-digit', // 'numeric' | '2-digit'
    hour: 'numeric', // 'numeric' | '2-digit'
    minute: 'numeric', // 'numeric' | '2-digit'
    // second: 'numeric', // 'numeric' | '2-digit'
    // timeZoneName: 'short', // 'short' | 'long'
    // timeZone: 'Asia/Shanghai', // Time zone to express it in
    hour12: false, // Force 12-hour or 24-hour
    hourCycle: 'h24', // 'h11' | 'h12' | 'h23' | 'h24' Rarely-used options
    formatMatcher: 'basic' // 'basic' | 'best fit'
  }
) => {
  let formattedDate = '';
  if (dateString) {
    const dateObj = new Date(dateString);
    formattedDate = !isNaN(dateObj.getDate())
      ? Intl.DateTimeFormat(locale, options).format(dateObj)
      : dateString;
  }

  return formattedDate;
};

export const formatDateSimple = (
  dateString,
  locale = 'de-CH',
  options = {
    year: 'numeric', // 'numeric' | '2-digit',
    month: '2-digit', // 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
    day: '2-digit', // 'numeric' | '2-digit'
    hour12: false, // Force 12-hour or 24-hour
    hourCycle: 'h24', // 'h11' | 'h12' | 'h23' | 'h24' Rarely-used options
    formatMatcher: 'basic' // 'basic' | 'best fit'
  }
) => {
  let formattedDate = '';
  if (dateString) {
    const dateObj = new Date(dateString);
    formattedDate = !isNaN(dateObj.getDate())
      ? Intl.DateTimeFormat(locale, options).format(dateObj)
      : dateString;
  }

  return formattedDate;
};

export const ucwords = (string, all = true) => {
  if (!string) {
    return '';
  }

  if (!all) {
    return string[0].toUpperCase() + string.substr(1);
  }

  return string.replace(/^(.)|\s+(.)/g, $1 => $1.toUpperCase());
};

export const decodeJwt = token => {
  try {
    if (!token) {
      throw new Error('Token not found.');
    }

    return jwtDecode(token);
  } catch (e) {
    throw e;
  }
};

export const hasRole = (roles, array) => {
  if (!Array.isArray(array)) {
    return roles.includes(array);
  }

  if (array.length === 0) {
    return false;
  }

  return roles.some(e => array.includes(e));
};

export const calculateExpiryDate = (iat, exp) => {
  const ttl = (exp - iat) * 1000;
  const expiryDate = new Date().getTime() + ttl;

  return new Date(expiryDate);
};

export const arrayToString = (array = [], delimiter = ', ') => {
  const result = array.map(
    (e, i) => e.name + (i !== array.length - 1 ? delimiter : '')
  );

  return result.length ? result : '';
};

export const zeroPad = (num, places) => {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
};

export const numberToCHF = number => {
  if (number !== null) {
    return new Intl.NumberFormat('de-CH', {
      style: 'currency',
      currency: 'CHF'
    }).format(number);
  }
};

export const withProps = injectedProps => WrappedComponent => props => {
  return <WrappedComponent {...injectedProps} {...props} />;
};

export const parseArrayErrors = obj => {
  // Do not modify parameter, create a new object instead
  const newObj = { ...obj };

  Object.keys(newObj).forEach(key => {
    if (key.includes('[')) {
      const keyName = key.split('[')[0];
      const index = key.split('[')[1].split(']')[0];
      const field = key
        .split('[')[1]
        .split(']')[1]
        .substr(1);
      const value = newObj[key];

      if (!Object.keys(newObj).includes(keyName)) {
        newObj[keyName] = [];
      }

      if (!newObj[keyName][index]) {
        newObj[keyName][index] = {};
      }

      newObj[keyName][index][field] = value;

      delete newObj[key];
    }
  });

  return newObj;
};

export const calculateDiff = (startDate, endDate) => {
  if (!endDate) {
    return false;
  }

  return moment(endDate.substring(0, 10)).diff(
    startDate.substring(0, 10),
    'days'
  );
};

export const formatFeedback = offer => {
  if (!offer) {
    throw new Error('Offer cannot be null.');
  }

  if (!offer.feedback) {
    return 'No feedback was received';
  }

  let text = '';

  if (offer.status.name === 'declined') {
    text += 'Your offer was declined. The reason is: ';
  } else {
    text += 'An alternative option was booked. The reason is: ';
  }

  offer.feedback.types.map((o, i) => {
    text += o.name + (i < offer.feedback.types.length - 1 ? ', ' : '.');
  });

  if (offer.feedback.comment) {
    text += `<br>Additional comment: ${offer.feedback.comment}`;
  }

  return text;
};

export const pendingStatusTypes = [
  'pending',
  'pending_confirmation',
  'pending_acceptance'
];

export const calculatevat = (price, vat, inclusive) => {
  let pricewithvat = 0;
  if(price){
    if(inclusive){
      pricewithvat = price;
    }
    else{
      price = price + (price * (vat / 100));
      pricewithvat = Math.round(price * 20, 2) / 20;
    }
  }
  return pricewithvat;
}
