import { combineReducers } from 'redux';

export function offerChargeTypesError(state = null, action) {
  switch (action.type) {
    case 'OFFER_CHARGE_TYPES_ERROR':
      return action.offerChargeTypesError;

    default:
      return state;
  }
}

export function offerChargeTypesLoading(state = false, action) {
  switch (action.type) {
    case 'OFFER_CHARGE_TYPES_LOADING':
      return action.offerChargeTypesLoading;

    default:
      return state;
  }
}

export function offerChargeTypesRetrieved(state = [], action) {
  switch (action.type) {
    case 'OFFER_CHARGE_TYPES_SUCCESS':
      return action.offerChargeTypesRetrieved;

    default:
      return state;
  }
}

export function offerChargeFrequenciesError(state = null, action) {
  switch (action.type) {
    case 'OFFER_CHARGE_FREQUENCIES_ERROR':
      return action.offerChargeFrequenciesError;

    default:
      return state;
  }
}

export function offerChargeFrequenciesLoading(state = false, action) {
  switch (action.type) {
    case 'OFFER_CHARGE_FREQUENCIES_LOADING':
      return action.offerChargeFrequenciesLoading;

    default:
      return state;
  }
}

export function offerChargeFrequenciesRetrieved(state = [], action) {
  switch (action.type) {
    case 'OFFER_CHARGE_FREQUENCIES_SUCCESS':
      return action.offerChargeFrequenciesRetrieved;

    default:
      return state;
  }
}

export function offerChargeVatError(state = null, action) {
  switch (action.type) {
    case 'OFFER_CHARGE_VAT_ERROR':
      return action.offerChargeVatError;

    default:
      return state;
  }
}

export function offerChargeVatLoading(state = false, action) {
  switch (action.type) {
    case 'OFFER_CHARGE_VAT_LOADING':
      return action.offerChargeVatLoading;

    default:
      return state;
  }
}

export function offerChargeVatRetrieved(state = [], action) {
  switch (action.type) {
    case 'OFFER_CHARGE_VAT_SUCCESS':
      return action.offerChargeVatRetrieved;

    default:
      return state;
  }
}

export function offerFeedbackTypesError(state = null, action) {
  switch (action.type) {
    case 'OFFER_FEEDBACK_TYPES_ERROR':
      return action.offerFeedbackTypesError;

    default:
      return state;
  }
}

export function offerFeedbackTypesLoading(state = false, action) {
  switch (action.type) {
    case 'OFFER_FEEDBACK_TYPES_LOADING':
      return action.offerFeedbackTypesLoading;

    default:
      return state;
  }
}

export function offerFeedbackTypesRetrieved(state = [], action) {
  switch (action.type) {
    case 'OFFER_FEEDBACK_TYPES_SUCCESS':
      return action.offerFeedbackTypesRetrieved;

    default:
      return state;
  }
}

export default combineReducers({
  offerChargeTypesError,
  offerChargeTypesLoading,
  offerChargeTypesRetrieved,
  offerChargeFrequenciesError,
  offerChargeFrequenciesLoading,
  offerChargeFrequenciesRetrieved,
  offerFeedbackTypesError,
  offerFeedbackTypesLoading,
  offerFeedbackTypesRetrieved,
  offerChargeVatError,
  offerChargeVatLoading,
  offerChargeVatRetrieved
});
