/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Table, NoAvailabilityTable, Detail } from '.';

const List = ({
  roles,
  request: req,
  setView,
  selectedOffer,
  noAvailableOffers
}) => {
  const [request, setRequest] = useState(req);
  const [offer, setOffer] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  return (

    <Row>
      <Col md={12}>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab>Offers</Tab>
            <Tab>
              {!!(
                noAvailableOffers &&
                noAvailableOffers['hydra:totalItems'] &&
                tabIndex === 0
              ) && (
                <>
                  <span className="text-pink">&#8226;</span>&nbsp;
                </>
              )}
              No availability
            </Tab>
          </TabList>
          <TabPanel>
            <Table
              request={request}
              offer={offer}
              setOffer={setOffer}
              roles={roles}
              selectedOffer={selectedOffer}
              setView={setView}
              setTabIndex={setTabIndex}
            />
            <Detail
              offer={offer}
              setOffer={setOffer}
              request={request}
              setRequest={setRequest}
              roles={roles}
            />
          </TabPanel>
          <TabPanel>
            <NoAvailabilityTable request={request} />
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
};

export default connect(state => {
  const { retrieved: noAvailableOffers } = state.noAvailableOffer.list;

  return {
    noAvailableOffers
  };
})(List);
