/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { toast } from 'react-toastify';
import { Col } from 'react-bootstrap';
import { BlockField } from '../Common/Form';
import { listOfferFeedbackTypes } from '../../actions/Offer/other';
import {
  updateStatus as updateOfferStatusAction,
  reset as resetOfferStatusAction
} from '../../actions/Offer/update';
import { iconClose } from '../Util';
import { OfferOverview as OfferConfirmOverviewModal } from '../Confirmation';

const Feedback = ({
  listOfferFeedbackTypes,
  types,
  updateOfferStatusAction,
  offer,
  handleSubmit,
  request
}) => {
  const [action, setAction] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [acceptOfferConfirmationState, setAcceptOfferConfirmationState] = useState(false);
  const myRef = useRef(null);

  useEffect(() => {
    listOfferFeedbackTypes();
  }, []);

  useEffect(() => {
    if (isFormVisible) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [isFormVisible]);

  return (
    <Col md={12}>
      {acceptOfferConfirmationState && (
        <OfferConfirmOverviewModal
          id={offer.id}
          modalState={acceptOfferConfirmationState}
          setModalState={setAcceptOfferConfirmationState}
          offer={offer}
          request={request}
          action={action}
          setAction={setAction}
        />
      )}
      <form
        onSubmit={handleSubmit(values => {
          updateOfferStatusAction(offer.id, action, values);
        })}
      >
        <div className="text-center text-md-right mt-4">
          <button
            type="button"
            className={`btn btn--decline-offer${
              request.hasAcceptedOffers ? ' mr-0' : ''
            }`}
            onClick={() => {
              setIsFormVisible(val => !val);
            }}
          >
            Decline Offer{' '}
            <img
              src={iconClose}
              alt="Decline offer"
              className="btn__close-icon"
            />
          </button>
          {!request.hasAcceptedOffers && (
            /*<button
              type="submit"
              className="btn btn--primary"
              onClick={() => {
                setAction('accept');
              }}
            >
              Accept Offer
            </button>*/
            <button
              type="button"
              className="btn btn--primary"
              onClick={() => {
                setAcceptOfferConfirmationState(true);
              }}
            >
              Accept Offer
            </button>
          )}
        </div>
        <div style={{ display: isFormVisible ? '' : 'none' }}>
          <hr className="my-4" />
          <h5 ref={myRef}>Add your feedback (optional)</h5>
          <Field
            component={BlockField}
            name="feedbackTypes"
            label="Reasons"
            type="multiSelect"
            options={(types || []).reduce((accumulator, current) => {
              accumulator.push({
                value: current.name,
                label: current.name
              });
              return accumulator;
            }, [])}
          />
          <Field
            component={BlockField}
            name="comment"
            label="Comment"
            type="textArea"
            maxlength={2000}
          />
          <small className="d-block font-italic mb-3">
            * If you wish to decline the offer without providing feedback, leave
            the form empty and simply click the Submit button.
          </small>
          <div className="text-md-right">
            <button
              type="submit"
              className="btn btn--primary"
              onClick={() => {
                setAction('decline');
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Col>
  );
};

const validate = values => {
  const errors = {};

  if (!values.feedbackTypes && values.comment) {
    errors.feedbackTypes =
      'At least one of the above reasons must be selected.';
  }

  return errors;
};

const Form = reduxForm({
  form: 'feedback',
  validate,
  onSubmitFail: (errors, dispatch, submitErrors, props) => {
    toast.error((errors ? errors._error : '') || props.error);
  },
  enableReinitialize: false
})(Feedback);

export default connect(
  state => {
    const { offerFeedbackTypesRetrieved } = state.offer.other;

    return {
      types: offerFeedbackTypesRetrieved
    };
  },
  { listOfferFeedbackTypes, updateOfferStatusAction, resetOfferStatusAction }
)(Form);
