import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, iconClose } from '../Util';
import { OfferDetails } from '../Confirmation';

const OfferOverview = ({
    modalState,
    setModalState,
    offer,
    request,
    action,
    setAction
}) => {

    return (
        <Modal isOpen={modalState}>
            <OfferDetails
                modalState={modalState}
                setModalState={setModalState}
                request={request}
                offer={offer}
                action={action}
                setAction={setAction}
            />
        </Modal>
    );
};

export default connect(
    null,
    null
)(OfferOverview);