import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { iconClose, Loader } from '../Util';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import moment from 'moment';
import { InlineFieldGroup, InlineField, BlockField } from '../Common/Form';
import {
    clientConfirm as clientConfirmAction
} from '../../actions/Offer/update';
import { toast } from 'react-toastify';
import {
    calculatevat
} from '../../utils/helpers';

let OfferDetails = ({
    modalState,
    setModalState,
    offer,
    request,
    billingToGuest,
    paymentType,
    startDate,
    endDate,
    fullamount,
    paymentTender,
    clientConfirmAction,
    handleSubmit,
    action,
    setAction,
    offerUpdateLoading,
    change,
}) => {
    useEffect(() => {
        change('amount', 0);
        if(paymentTender === process.env.REACT_APP_PAYMENT_TYPE_FULL && paymentType === process.env.REACT_APP_PAYMENT_TYPE_AMOUNT){
            change('amount', fullamount);
        }
    }, [paymentTender, paymentType]);
  return (
    <div className="modal__content">
        {offerUpdateLoading && <Loader />}
        <form
            onSubmit={handleSubmit(values => {
                clientConfirmAction(offer, request, values);
            })}
        >
            <Row>
                <Col md={12} className="pr-5">
                    <h1 className="heading heading--medium">
                        Confirm Offer #{offer.id}
                    </h1>
                    <button
                        type="button"
                        className="btn btn--close-modal"
                        onClick={() => setModalState(!modalState)}
                        >
                        <img src={iconClose} alt="close modal" />
                    </button>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Field
                        component={BlockField}
                        name="billingToGuest"
                        type="checkbox"
                        label="Guest is fully/partially paying"
                        labelClass="col-sm-4"
                        inputClass="col-sm-8"
                    />
                    <Field
                        component={BlockField}
                        name="paymentTender"
                        label="Payment"
                        type="selectdirect"
                        labelClass="col-sm-4"
                        inputClass="col-sm-8"
                        defaultval={`${process.env.REACT_APP_PAYMENT_TYPE_FULL}`}
                        items={[
                            {
                                value: process.env.REACT_APP_PAYMENT_TYPE_FULL,
                                name: 'Fully'
                            },
                            {
                                value: process.env.REACT_APP_PAYMENT_TYPE_PARTIAL,
                                name: 'Partially'
                            }
                        ]}
                        required={billingToGuest}
                        disabled={!billingToGuest}
                    />
                    {billingToGuest && <InlineFieldGroup 
                        label="Start Date / End Date"
                        labelClass="col-sm-4"
                        childrenClass="col-sm-8"
                        required={billingToGuest}
                    >
                        <Field
                            component={InlineField}
                            name="startDate"
                            label="From"
                            type="dateTime"
                            placeholder="From (DD.MM.YYYY)"
                            required={billingToGuest}
                            isOutsideRange={day => {
                                return endDate
                                    ? moment(endDate)
                                        .subtract(1, 'day')
                                        .isBefore(day)
                                    : false;
                            }}
                        />
                        <Field
                            component={InlineField}
                            name="endDate"
                            label="To"
                            type="dateTime"
                            placeholder="To (DD.MM.YYYY)"
                            required={billingToGuest}
                            isOutsideRange={day => {
                                return startDate
                                    ? moment(startDate)
                                        .add(1, 'day')
                                        .isAfter(day)
                                    : false;
                            }}
                        />
                    </InlineFieldGroup>}
                    
                    <InlineFieldGroup
                        label="Payment / Amount"
                        labelClass="col-sm-4"
                        childrenClass="col-sm-8"
                        required={billingToGuest}
                    >
                        <Field
                            component={InlineField}
                            name="paymentType"
                            label="Payment Type"
                            type="selectdirect"
                            defaultval={`${process.env.REACT_APP_PAYMENT_TYPE_AMOUNT}`}
                            items={[
                                {
                                    value: process.env.REACT_APP_PAYMENT_TYPE_AMOUNT,
                                    name: 'Amount'
                                },
                                {
                                    value: process.env.REACT_APP_PAYMENT_TYPE_PERCENTAGE,
                                    name: 'Percentage'
                                }
                            ]}
                            required={billingToGuest}
                            disabled={!billingToGuest}
                        />
                        {billingToGuest && <Field
                            component={InlineField}
                            name="amount"
                            type={(paymentType === process.env.REACT_APP_PAYMENT_TYPE_AMOUNT) ? "currencyInput" : "text"}
                            label="Amount"
                            placeholder="Amount"
                            required={billingToGuest}
                            disabled={!billingToGuest}
                        />}
                        {!billingToGuest && <Field
                            component={InlineField}
                            name="amount"
                            type="text"
                            label="Amount"
                            placeholder="Amount"
                            required={billingToGuest}
                            disabled={!billingToGuest}
                        />}
                    </InlineFieldGroup>
                    <Field
                        component={BlockField}
                        name="address"
                        type="text"
                        label="Address"
                        labelClass="col-sm-4"
                        inputClass="col-sm-8"
                        placeholder="123 Main Street"
                        disabled={!billingToGuest}
                        required
                    />
                    <InlineFieldGroup
                        label="Zip / City"
                        labelClass="col-sm-4"
                        childrenClass="col-sm-8"
                        required
                        >
                        <Field
                        component={InlineField}
                        name="zip"
                        type="text"
                        inputClass="col-4"
                        label="Address*"
                        placeholder="8004"
                        disabled={!billingToGuest}
                        required
                        />
                        <Field
                        component={InlineField}
                        name="city"
                        type="text"
                        placeholder="Zürich"
                        inputClass="col-8"
                        label="Address*"
                        disabled={!billingToGuest}
                        required
                        />
                    </InlineFieldGroup>
                    <Field
                        component={BlockField}
                        name="country"
                        type="text"
                        label="Country"
                        labelClass="col-sm-4"
                        inputClass="col-sm-8"
                        placeholder="Switzerland"
                        disabled={!billingToGuest}
                        required
                    />
                    <Field
                        component={BlockField}
                        name="clientEmail"
                        type="text"
                        label="Email"
                        labelClass="col-sm-4"
                        inputClass="col-sm-8"
                        disabled={!billingToGuest}
                        required
                    />
                    <Field
                        component={BlockField}
                        name="referencenr"
                        type="text"
                        label="Reference Nr"
                        labelClass="col-sm-4"
                        inputClass="col-sm-8"
                    />
                    <Field
                        component={BlockField}
                        name="comment"
                        label="Remarks"
                        labelClass="col-sm-4"
                        inputClass="col-sm-8"
                        type="wysiwig"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="float-right">
                        <button
                            type="submit"
                            className="btn btn--primary"
                            onClick={() => setAction('accept')}
                        >
                            Confirm Offer
                        </button>
                    </div>
                </Col>
            </Row>
        </form>
    </div>
    );
};

const validate = (
    values
  ) => {
    const errors = {};

    if(values.billingToGuest){
        if(!values.paymentTender){
            errors.paymentTender = 'Full / Partial is required'
        }
        if(!values.startDate){
            errors.startDate = 'Start date is required'
        }
        if(!values.endDate){
            errors.endDate = 'End date is required'
        }
        if(!values.paymentType){
            errors.paymentType = 'Payment Type is required'
        }
        if(!values.amount || values.amount == 0){
            errors.amount = 'Amount is required'
        }
    }
  
    if (!values.address) {
      errors.address = 'Address is required.';
    }

    if (!values.zip) {
        errors.zip = 'Zip is required.';
    }

    if (!values.city) {
        errors.city = 'City is required.';
    }

    if (!values.country) {
        errors.country = 'Country is required.';
    }

    if (!values.clientEmail) {
        errors.clientEmail = 'Email is required.';
    }
  
    if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
      return {
        ...errors,
        _error: 'Submission failed. Please fix the fields marked in red.'
      };
    }
  
    return errors;
  };

OfferDetails = reduxForm({
    form: 'offerdetails',
    validate,
    onSubmitFail: (errors, dispatch, submitErrors, props) => {
        toast.error((errors ? errors._error : '') || props.error);
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(OfferDetails);

const selector = formValueSelector('offerdetails');

export default connect(
    (state, { request, offer }) => {

        const {billingToGuest, paymentType, startDate, endDate, paymentTender} = selector(state, 'billingToGuest', 'paymentType', 'startDate','endDate', 'paymentTender');

        const {updateLoading: offerUpdateLoading} = state.offer.update;

        let defaultEmail = request.user.email;
        if(request.guestEmail)
        {
            defaultEmail = request.guestEmail;
        }

        let fullamount1 = 0;
        let fullamount = 0;
        try{
            fullamount += calculatevat(offer.price, offer.vatPrice === "" ? process.env.REACT_APP_VAT_HOSPITALITY_FEE : offer.vatPrice, offer.vatCategory === "inclusive");
            fullamount += calculatevat(offer.endCleaningFee, offer.vatCleaning === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatCleaning, offer.vatCategory === "inclusive");
            fullamount += calculatevat(offer.petFee, offer.vatPet === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatPet, offer.vatCategory === "inclusive");
            fullamount += calculatevat(offer.babyPackageFee, offer.vatBaby === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatBaby, offer.vatCategory === "inclusive");
            fullamount += calculatevat(offer.parkingFee, offer.vatParking === "" ? process.env.REACT_APP_VAT_SERVICE_FEE : offer.vatParking, offer.vatCategory === "inclusive");
            offer.charges.map(e => fullamount += calculatevat(e.price, e.vatPercent, offer.vatCategory === "inclusive"));

            const factor = Math.pow(10, 2);
            fullamount = Math.round(fullamount * factor) / factor;
        }
        catch(e){console.log(e.message);}
        if(paymentTender === process.env.REACT_APP_PAYMENT_TYPE_FULL && paymentType === process.env.REACT_APP_PAYMENT_TYPE_AMOUNT){
            fullamount1 = fullamount;
        }

        let initialValues = {
            billingToGuest: false,
            clientEmail: defaultEmail,
            address: request.user.company.address,
            zip: request.user.company.zip,
            city: request.user.company.city,
            country: request.user.company.country,
            paymentTender: process.env.REACT_APP_PAYMENT_TYPE_FULL,
            paymentType: process.env.REACT_APP_PAYMENT_TYPE_AMOUNT,
            //amount: fullamount1,
        };

        return {
            billingToGuest,
            paymentType,
            startDate,
            endDate,
            fullamount,
            paymentTender,
            initialValues,
            offerUpdateLoading,
        };
    },
    {clientConfirmAction,change}
)(OfferDetails);